<template>
  <div class="bg-color detail-wrap" ref="wrap">
    <div class="container pt-5 pb-5 h-100">
      <h4 class="title text-center">{{ content.title }}</h4>
      <div class="w-100 text-center">
        <small class="text-muted">{{ content.publishTime }}</small>
      </div>

      <hr />

      <p class="abstract text-muted bg-light rounded-sm p-2">
        {{ content.summary }}
      </p>
      <div class="lead" v-html="content.content">
        <!-- {{content.content}} -->
      </div>
      <!-- 上下篇 -->
      <hr />
      <div class="pre-next">
        <template v-if="content.nextNews">
          <h6
            v-if="content.nextNews"
            @click="lastNextNews(content.nextNews.newsId)"
            class="ellipsis-num-1 w-50 pre"
          >
            上一篇:{{ nextNewTitle }}
          </h6>
        </template>
        <template v-else>
          <h6 class="stopClick">上一篇:无</h6>
        </template>

        <template v-if="content.lastNews">
          <h6
            @click="lastNextNews(content.lastNews.newsId)"
            class="ellipsis-num-1 w-50 text-right next"
          >
            下一篇:{{ lastNewTitle }}
          </h6>
        </template>
        <template v-else>
          <h6 class="stopClick">下一篇:无</h6>
        </template>
      </div>
    </div>
    <div class="top-back">
      <div class="top" v-show="flag_scroll" @click="backTop">
        <img src="@/static/image/new-top.png" class="w-40" alt="" />
      </div>
      <div class="back" @click="backNewList">
        <img src="@/static/image/new-back.png" class="w-40" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import baseUrl from "@/utils/request.js";
export default {
  data() {
    return {
      content: [],
      nextNewTitle: "暂无",
      lastNewTitle: "暂无",
      flag_scroll: false,
    };
  },
  mounted() {
    let id = this.$route.query.id;
    this.getDetail(id);
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true); //移除监听事件
  },

  methods: {
    // 返回新闻列表
    backNewList() {
      this.$router.push({ name: "about", params: { index: 4 } });
    },
    // 返回顶部事件
    backTop() {
      document.documentElement.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      window.pageYOffset = 0;
    },
    //滑动超过200时显示按钮
    handleScroll() {
      // let scrollTop = this.$refs.wrap.scrollTop
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log("99999", scrollTop, this.$refs.wrap);
      if (scrollTop > 200) {
        this.flag_scroll = true;
      } else {
        this.flag_scroll = false;
      }
    },
    // 获取新闻详情
    getDetail(id) {
      this.$axios
        .get(`${baseUrl.BASE_URL}/document/news/info/${id}`)
        .then((res) => {
            this.$loading.show()
          this.content = res.data.data;
          if (res.data.data.nextNews != null) {
            this.nextNewTitle = res.data.data.nextNews.title;
          }
          if (res.data.data.lastNews != null) {
            this.lastNewTitle = res.data.data.lastNews.title;
          }
            this.$loading.hide()
        })
        .catch((error) => {
           this.$loading.hide()
          console.log("失败", error);
        });
    },
    lastNextNews(newsId = null) {
      if (newsId != null) {
        this.$router.push({
          name: "newdetail",
          params: { id: newsId },
        });
        this.getDetail(newsId);
      }
    },
  },
};
</script>
<style lang="less">
.detail-wrap {
  position: relative;
  .top-back {
    cursor: pointer;
    position: fixed;
    right: 20px;
    bottom: 150px;
    .top {
      margin-bottom: 20px;
    }
    .w-40 {
      width: 40%;
    }
    .top,
    .back {
      border: 1px solid #e5e5e5;
      padding: 10px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
    }
  }
}
.bg-color {
  background-color: #f6f3ff;
  height: 100%;
}
.pre-next {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: #333;
  font-size: 0.5rem;
}
.pre:hover,
.next:hover {
  color: #ff4484;
}
.lead p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.5;
}
.ellipsis-num-1 {
  display: -ms-box;
  display: -moz-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal !important;
  -webkit-line-clamp: 1;
  white-space: normal !important;
  -ms-line-clamp: 1;
  white-space: normal !important;
  -moz-line-clamp: 1;
  -webkit-box-orient: vertical;
  -ms-box-orient: vertical;
  -moz-box-orient: vertical;
}
.stopClick {
  cursor: not-allowed;
}
</style>
